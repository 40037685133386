// Welcome to Code in Framer
// Get Started: https://www.framer.com/docs/guides/

// import Example from "https://framer.com/m/framer/Example.js@^1.0.0"
// import styled from "styled-components"

// const StyledSelect = styled(Select)`
// :after {
//     background-color: black;
// }
// `

// const StyledSelect = styled.select`
//     border: 1px solid #4B4B4B;
//     border-radius: 4px;
//     padding: 16px;
//     display: block;
//     font-family: "F37Neuro", sans-serif;
//     font-size: 16px;
//     width: 100%;
//     :after {
//         content: "";
//         width: 0.8em;
//         height: 0.5em;
//         background-color: black;
//         clip-path: polygon(100% 0%, 0 0%, 50% 100%);
//     }
// `

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Select({ children, ...props }) {
    return <select {...props}>{children}</select>
}
